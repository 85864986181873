import { theme as chakraTheme, DefaultTheme, ColorHues } from '@chakra-ui/core'

const theme = {
	...chakraTheme,
	breakpoints: ['1023px', '1280px'],
	fonts: {
		body: 'Roboto, sans-serif',
		heading: 'Poppins, serif',
		mono: 'Poppins, monospace'
	},
	colors: {
		...chakraTheme.colors,
		gray: {
			...chakraTheme.colors.gray,
			'100': '#FAFAFB',
			'200': '#F1F1F5',
			'300': '#e2e2ea',
			'400': '#D5D5DC',
			'500': '#B5B5BE',
			'600': '#92929D',
			'700': '#696974',
			'800': '#44444f',
			'900': '#3D3D46'
		},
		brand: {
			'50': '#d3f4ff',
			'100': '#b3f4ff',
			'200': '#6bd8fa',
			'300': '#3accf8',
			'400': '#08b5ea',
			'500': '#0D9FD5',
			'600': '#0C8FC0',
			'700': '#0d77a5',
			'800': '#04547b',
			'900': '#01314c'
		}
	}
}

// copy from @types, not exported
interface Colors {
	transparent: string
	current: string
	black: string
	white: string
	whiteAlpha: ColorHues
	blackAlpha: ColorHues
	gray: ColorHues
	red: ColorHues
	orange: ColorHues
	yellow: ColorHues
	green: ColorHues
	teal: ColorHues
	blue: ColorHues
	cyan: ColorHues
	purple: ColorHues
	pink: ColorHues
	linkedin: ColorHues
	facebook: ColorHues
	messenger: ColorHues
	whatsapp: ColorHues
	twitter: ColorHues
	telegram: ColorHues
}

interface CustomColors extends Colors {
	// put your custom colors here, if you want to use the theme in a useTheme<CustomTheme>() hook.
	brand: ColorHues
}

export interface CustomTheme extends DefaultTheme {
	colors: CustomColors
}

export default theme
