import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

const buildMeta = ({
	meta,
	metaDescription,
	title,
	author,
	keywords
}: {
	meta: any[]
	metaDescription: string
	title: string
	author: string
	keywords: string[]
}) =>
	[
		{
			content: metaDescription,
			name: `description`
		},
		{
			content: title,
			property: `og:title`
		},
		{
			content: metaDescription,
			property: `og:description`
		},
		{
			content: `website`,
			property: `og:type`
		},
		{
			content: `summary`,
			name: `twitter:card`
		},
		{
			content: author,
			name: `twitter:creator`
		},
		{
			content: title,
			name: `twitter:title`
		},
		{
			content: metaDescription,
			name: `twitter:description`
		}
	]
		.concat(
			keywords.length > 0
				? {
						content: keywords.join(`, `),
						name: `keywords`
				  }
				: []
		)
		.concat(meta)

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				description
				author
			}
		}
	}
`
interface SEOProps {
	description?: string
	lang?: string
	meta?: any[]
	keywords?: string[]
	title?: string
}

function SEO({ description, lang = 'pt-br', meta = [], keywords = [], title }: SEOProps) {
	const data = useStaticQuery(detailsQuery)
	const metaDescription = description || data.site.siteMetadata.description
	const builtMeta = buildMeta({
		author: data.site.siteMetadata.author,
		keywords,
		meta,
		metaDescription,
		title: title || data.site.siteMetadata.titlec
	})

	return (
		<Helmet
			htmlAttributes={{ lang }}
			title={title || data.site.siteMetadata.title}
			titleTemplate={title && `%s | ${data.site.siteMetadata.title}`}
			meta={builtMeta}
		/>
	)
}

export default SEO
