import React from 'react'
import SEO from './seo'

import './globalFonts.css'

import { RevealGlobalStyles } from 'react-genie'

import { CookiesProvider } from 'react-cookie'
import { ThemeProvider, CSSReset, Box, IconButton } from '@chakra-ui/core'
import customTheme from './customTheme'

import favicon from '../images/favicon.png'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { FaArrowUp } from 'react-icons/fa'
import { useViewportScroll, motion } from 'framer-motion'

import useScrollPosition from '@react-hook/window-scroll'
import LGPDNotice from './LGPDNotice'

const Layout: React.FunctionComponent = ({ children }) => {
	const scrollY = useScrollPosition(60 /*fps*/)

	return (
		<ThemeProvider theme={customTheme}>
			<CSSReset />
			<RevealGlobalStyles />
			<CookiesProvider>
				<SEO
					keywords={[`valid`, `receita`, `médica`, `médico`, `farmácia`, `farmacêutico`, `assinatura`, `digital`]}
					meta={[
						{
							content: favicon,
							property: `og:image`
						},
						{
							content: 'image/png',
							property: `og:image:type`
						},
						{
							content: '250',
							property: `og:image:width`
						},
						{
							content: '250',
							property: `og:image:height`
						}
					]}
					title='Home'
				/>
				<Box as='main' pos='relative'>
					{children}
					{/* <motion.div
						initial={{ opacity: 1 }}
						// animate={{
						// 	opacity: scrollYProgress > 200 ? 1 : 0
						// }}
					> */}
					{scrollY > 500 && (
						<IconButton
							pos='fixed'
							bottom={5}
							right={5}
							isRound
							icon={FaArrowUp}
							aria-label='Voltar ao topo'
							onClick={() => scrollTo('#top')}
							size='lg'
							variantColor='brand'
						/>
					)}

					<LGPDNotice />

					{/* </motion.div> */}
				</Box>
			</CookiesProvider>
		</ThemeProvider>
	)
}

export default Layout
