import React, { useCallback } from 'react'
import { Stack, Link, Button, Text, IconButton } from '@chakra-ui/core'
import { useCookies } from 'react-cookie'

const LGPDNotice = () => {
	const [cookies, setCookie, removeCookie] = useCookies()

	const acceptOrClose = useCallback(() => {
		setCookie('lgpd-notice', true, {
			maxAge: 31536000 // 1 year
		})
	}, [setCookie])

	const hasAlreadyClicked = useCallback(() => Boolean(cookies['lgpd-notice']), [cookies])

	if (hasAlreadyClicked()) return

	return (
		<Stack
			isInline
			flexDirection={['column', 'row']}
			spacing={4}
			pos='fixed'
			bg='gray.100'
			bottom={0}
			boxShadow='0px 16px 87px 43px rgba(0,0,0,0.1)'
			w='100vw'
			maxW='100%'
			p={4}
			px={8}
			zIndex={10}
			alignItems={['flex-start', 'center']}
			borderLeft='5px solid'
			borderColor='brand.500'
		>
			<Text color='gray.900'>
				Utilizamos cookies para melhorar a experiência dos nossos serviços. Ao continuar navegando você está ciente e de
				acordo com nossa política de privacidade e cookies. Clique no botão para concordar ou{' '}
				<Link
					color='brand.500'
					target='_blank'
					href='https://www.validcertificadora.com.br/politica-de-privacidade-e-cookies'
					rel='noopener'
				>
					leia mais
				</Link>
			</Text>
			<Button
				variantColor='brand'
				rounded='20px'
				fontFamily='Poppins'
				fontWeight='500'
				px={6}
				mt={[4, 0]}
				onClick={acceptOrClose}
			>
				Aceito
			</Button>
			<IconButton
				icon='small-close'
				aria-label='fechar aviso da LGPD'
				minW='1em'
				h='1em'
				pos='absolute'
				top={2}
				right={2}
				onClick={acceptOrClose}
			/>
		</Stack>
	)
}

export default LGPDNotice
